import * as React from 'react';
import { InPageNavigationServiceV1 } from '@volkswagen-onehub/audi-in-page-navigation-service';
import { Logger } from '@feature-hub/core';

export interface FeatureAppContextState {
  readonly logger: Logger;
  readonly inPageNavigationService?: InPageNavigationServiceV1;
}

const FeatureAppContext = React.createContext<FeatureAppContextState | null>(null);

export interface FeatureAppContextProviderProps {
  readonly logger: Logger;
  readonly inPageNavigationService?: InPageNavigationServiceV1;
}

export function FeatureAppContextProvider({
  logger,
  inPageNavigationService,
  children,
}: React.PropsWithChildren<FeatureAppContextProviderProps>) {
  return (
    <FeatureAppContext.Provider value={{ logger, inPageNavigationService }}>
      {children}
    </FeatureAppContext.Provider>
  );
}

function getContext() {
  const context = React.useContext(FeatureAppContext);
  if (context === null) {
    throw Error('Did you forget to wrap your Feature App in <FeatureAppContextProvider>?');
  }
  return context;
}

export function useLogger() {
  return getContext().logger;
}

export function useInPageNavigationService() {
  return getContext().inPageNavigationService;
}
