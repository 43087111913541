import { Logger } from '@feature-hub/core';
import React, { useEffect, useState } from 'react';
import { useContent } from '@volkswagen-onehub/audi-etron-gt-utils-feature-app';
import { RenderMode } from '@volkswagen-onehub/audi-render-mode-service';

import Anchor, { AnchorProps } from './Anchor';
import { AsyncStateHolder, createInitialState } from '../FeatureHubAppDefinition';
import { Content } from '../ContentType';

interface AppProps {
  readonly asyncStateHolder: AsyncStateHolder;
  readonly logger: Logger;
  readonly renderMode: RenderMode;
}

export function App({ asyncStateHolder, logger, renderMode }: AppProps) {
  // when asyncStateHolder is an object it represents the serialized state coming from the server
  // ready to be used as the initial state
  const [state, setState] = useState<AnchorProps | undefined>(
    typeof asyncStateHolder === 'object' ? asyncStateHolder : undefined
  );

  const content = useContent<Content>();

  React.useEffect(() => {
    let mounted = true;
    if (state && content) {
      createInitialState(content, renderMode).then((newState) => {
        if (mounted) {
          setState(newState);
        }
      });
    }
    return () => {
      mounted = false;
    };
  }, [content]);

  useEffect(() => {
    // when asyncStateHolder is a function it means the state could not be properly serialized by
    // the server and it is not available on the client. In that case this effect will try to fetch
    // the state as soon as the component is mounted on the client.
    if (typeof asyncStateHolder === 'function') {
      logger?.info('SSR did not serialize any state');
      asyncStateHolder().then((anchorProps) => {
        if (anchorProps) {
          setState({
            fallbackRegistration: true,
            ...anchorProps,
          });
        }
      });
    } else {
      logger?.info('SSR serialized state: ', asyncStateHolder);
    }
  }, []);

  if (!state) {
    return null;
  }
  return <Anchor {...state} />;
}
